import { Link } from "gatsby"
import React from "react"
import { Card } from "react-bootstrap"
import { withTrans } from "../../../../i18n/withTrans"

const Index = ({ date, image, title, total, slug, key, t }) => {
  let sortTitle = title
  // const length = 25
  // if (title.length > length) {
  //   sortTitle = title.substring(0, length) + " ..."
  // }

  return (
    <>
      <Link key={key} className="individual-agenda" href={slug}>
        <Card style={{ margin: 20, height: 450 }}>
          <Card.Img
            variant="top"
            style={{
              objectFit: "cover",
              display: "block",
            }}
            height={325}
            src={`${
              image ? image : "../../../../images/Agenda_default-image.png"
            }`}
          />
          <Card.Body style={{ height: 125 }}>
            <div className="d-flex flex-column justify-content-between h-100">
              <span style={{ opacity: ".5", fontSize: 14 }}>{date}</span>
              <div className="d-flex flex-column h-100">
                <span style={{ fontSize: 20 }}>{sortTitle}</span>
              </div>
            </div>
          </Card.Body>
          {/* <Card.Body
          style={{
            color: "#00a650",
            textAlign: "center",
            borderTop: "1px solid rgba(0,0,0,.125)",
          }}
          className="card-kegiatan"
        >
          <Link key={key} href={slug}>
            {t("seeDetail")}
          </Link>
        </Card.Body> */}
        </Card>
      </Link>
    </>
  )
}

export default withTrans(Index)
